import { cloneDeep, isNil } from 'lodash';
import { handleResponse } from '@/services/index.js';
import axios from 'axios';
import moment from 'moment';

const api_url_module = '/programs/';

export const programService = {
  getAll,
  get,
  getStatus,
  update,
  updatePublication,
  updateRank,
  syncToAmazon,
  create,
  search,
  deleteProgram,
  getTimecodes,
  createTimecode,
  updateTimecode,
  deleteTimecode,
  sendToPartners,
  packagesStatuses,
  deletePackages,
  unpublishPackage,
};

async function getAll(queryString = '') {
  if (queryString !== '') {
    queryString = '?' + queryString;
  }
  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + queryString
    );
    return handleResponse(response.data);
  } catch (response_1) {
    return [];
  }
}

async function get(id) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL +
      api_url_module +
      id +
      '?with=video-details&cache=nocache'
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function getStatus(id) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + id + '/status'
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function update(program, id) {
  const requestOptions = {
    method: 'PUT',
  };
  try {
    const response = await axios.put(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + id,
      program,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function updateRank({ id, body }) {
  const requestOptions = {
    method: 'PUT',
  };
  try {
    const response = await axios.put(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + id + '/rank/',
      body,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function updatePublication(publicationBrut, id, countryId = null) {
  const publication = cloneDeep(publicationBrut) ?? {};
  publication.is_free = publicationBrut.is_free ?? 0;
  publication.country_id = publicationBrut.country_id ?? countryId;
  publication.rights_starts_at =
    publicationBrut.rights_starts_at ?? publicationBrut.publish_at;
  publication.rights_ends_at =
    publicationBrut.rights_ends_at ?? publicationBrut.unpublish_at;
  if (publicationBrut.publish_at !== null) {
    publication.publish_at =
      publicationBrut.publish_at ??
      moment().format('YYYY-MM-DDTHH:mm:ss.SSSSSS');
    publication.publish_now = true;
  }

  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + id + '/publish',
      publication,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function syncToAmazon(data) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + '/programs/amazon-package',
      data,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function create(program) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module,
      program,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function search(needle, queryString = '', config) {
  const requestOptions = {
    method: 'POST',
  };
  const data = {
    query: needle,
    custom_config: config ?? undefined,
  };

  if (queryString !== '') {
    queryString = '?' + queryString;
  }
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + '/programs/search/' + queryString,
      data,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function deleteProgram(id) {
  const requestOptions = {
    method: 'DELETE',
  };

  try {
    const response = await axios.delete(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module + id,
      requestOptions
    );
    return handleResponse(response);
  } catch (err) {
    return await Promise.reject(err.response);
  }
}

async function getTimecodes(id) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL +
      api_url_module +
      id +
      '/time-codes'
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function createTimecode(id, timecode) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL +
      api_url_module +
      id +
      '/time-codes',
      timecode,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function updateTimecode(timecodeId, programId, timecode) {
  const requestOptions = {
    method: 'PUT',
  };
  try {
    const response = await axios.put(
      import.meta.env.VITE_CONTENT_BASE_URL +
      api_url_module +
      programId +
      '/time-codes/' +
      timecodeId,
      timecode,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function deleteTimecode(timecodeId, programId) {
  try {
    const response = await axios.delete(
      import.meta.env.VITE_CONTENT_BASE_URL +
      api_url_module +
      programId +
      '/time-codes/' +
      timecodeId
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function sendToPartners(id, payload) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module 
      + id + '/send-package',
      payload,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function packagesStatuses(id) {
  try {
    const response = await axios.get(
      import.meta.env.VITE_CONTENT_BASE_URL +
      api_url_module +
      id + '/packages-statuses'
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

async function deletePackages(id, payload) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module 
      + id + '/delete-packages',
      payload,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
};

async function unpublishPackage(id, payload) {
  const requestOptions = {
    method: 'POST',
  };
  try {
    const response = await axios.post(
      import.meta.env.VITE_CONTENT_BASE_URL + api_url_module 
      + id + '/unpublish-package',
      payload,
      requestOptions
    );
    return handleResponse(response.data);
  } catch (err) {
    return handleResponse(err);
  }
}

