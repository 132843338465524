import '@/assets/tailwind.css';
import '@/index.css';
import 'floating-vue/dist/style.css';
import 'swiper/dist/css/swiper.min.css';
import 'vue-croppa/dist/vue-croppa.css';
import * as Sentry from '@sentry/vue';
import { Calendar, DatePicker, SetupCalendar } from 'v-calendar';
import { Integrations } from '@sentry/tracing';
import { plugin as VueInputAutowidth } from 'vue-input-autowidth';
import { assetUrl } from '@/data/constants.js';
import { autoAnimatePlugin } from '@formkit/auto-animate/vue';
import { can, hidden } from '@/directives/index.js';
import { createApp } from 'vue';
//import { mockServer } from '@/mocks/server.js';
import { vfmPlugin } from 'vue-final-modal';
import App from '@/App.vue';
import Default from '@/layouts/Default.vue';
import FloatingVue from 'floating-vue';
import Jimo from '@/plugins/jimo.js';
import LoaderSmall from '@/components/loader/LoaderSmall.vue';
import Maska from 'maska';
import Nl2br from 'vue3-nl2br';
import NoNavbar from '@/layouts/NoNavbar.vue';
import RichTextEditor from '@/components/form/RichTextEditor.vue';
import RichTextViewer from '@/components/form/RichTextViewer.vue';
import VueHotjar from 'vue-hotjar';
import VueSnip from 'vue-snip';
import VueUploadComponent from 'vue-upload-component/dist/vue-upload-component.esm';
import VueVirtualScroller from 'vue-virtual-scroller';
import Zendesk from '@/plugins/zendesk.js';
import draggable from 'vuedraggable';
import filters from '@/filters/index.js';
import i18n from '@/i18n';
import interceptorsSetup from './helpers/axiosInterceptor';
import router from '@/router';
import store from '@/store';
import uploader from 'vue-simple-uploader';
import vClickOutside from 'click-outside-vue3';

interceptorsSetup();

const app = createApp(App);
app.config.productionTip = false;
app.config.unwrapInjectedRef = true;

const user = store.state.auth.authUser;

if (import.meta.env.VITE_SENTRY_DSN) {
  Sentry.init({
    app,
    environment: import.meta.env.VITE_ENV,
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [
      new Integrations.BrowserTracing({
        tracingOrigins: [
          new URL(import.meta.env.VITE_ANALYTICS_BASE_URL).hostname,
          new URL(import.meta.env.VITE_CONTENT_BASE_URL).hostname,
          new URL(import.meta.env.VITE_MEDIA_BASE_URL).hostname,
          new URL(import.meta.env.VITE_USER_BASE_URL).hostname,
          new URL(import.meta.env.VITE_BILLING_BASE_URL).hostname,
          new URL(import.meta.env.VITE_PLATFORM_BASE_URL).hostname,
        ],
      }),
    ],
    ignoreErrors: ['ResizeObserver loop limit exceeded'],
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
  });
  if (user) {
    Sentry.configureScope((scope) => {
      scope.setUser({ id: user.id });
      scope.setTag('email', user.email);
      scope.setTag('platform.id', user.platform_id);
    });
  }
}

app.use(router);
app.use(store);
app.use(i18n);
app.use(FloatingVue, {
  themes: {
    'options-tooltip': {
      $extend: 'tooltip',
      distance: 10,
    },
  },
});

if (import.meta.env.VITE_ENV === 'production') {
  app.use(VueHotjar, {
    id: import.meta.env.VITE_HOTJAR_ID,
    isProduction: true
  });
};


app.use(Maska);
app.use(uploader);
app.use(VueSnip);
app.use(vfmPlugin);
app.use(VueVirtualScroller);
app.use(vClickOutside);
app.use(autoAnimatePlugin);
app.use(SetupCalendar, {});
app.use(VueInputAutowidth);

app.use(Zendesk, {
  key: import.meta.env.VITE_ZENDESK_KEY,
  disabled: true,
  hideOnLoad: false,
});

app.use(Jimo, {
  key: import.meta.env.VITE_JIMO_KEY,
});

// Plugins components
app.component('Nl2br', Nl2br);
app.component('Calendar', Calendar);
app.component('DatePicker', DatePicker);
app.component('DefaultLayout', Default);
app.component('NoNavbarLayout', NoNavbar);
app.component('FileUpload', VueUploadComponent);
app.component('LoaderSmall', LoaderSmall);
app.component('Draggable', draggable);
app.component('RichTextEditor', RichTextEditor);
app.component('RichTextViewer', RichTextViewer);

// Custom filters
app.config.globalProperties.$filters = filters;
app.config.globalProperties.importMetaUrl = import.meta.url;
app.config.globalProperties.assetUrl = assetUrl;

// Dev environment mock server
if (import.meta.env.VITE_ENV === 'local') {
  //mockServer();
}
// Custom directives
app.directive('hidden', hidden);
app.directive('can', can);
app.directive('tooltip', FloatingVue.VTooltip);

app.mount('#app');
