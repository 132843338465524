<template>
  <div
    ref="formSelect"
    class="form-select inline-flex cursor-pointer gap-2 rounded-ui-component flex-nowrap justify-between peer-disabled:cursor-not-allowed group-disabled:cursor-not-allowed"
    :class="[uiElementFieldClass]"
    :style="width && `max-width: ${width};`"
  >
    <div class="inline-flex items-start w-full -my-px">
      <Dropdown
        :name="formSelectId"
        type="select"
        class="flex md:gap-2 min-w-full flex-col flex-wrap md:flex-nowrap md:flex-row"
        nude
        :disabled="disabled"
        :full-width-toggle="
          !multiple ||
            ['list', 'count'].includes(multipleDisplay) ||
            display === 'table'
        "
        :size="size"
        :field="true"
        auto-size
        :class="[uiElementThemeClass, uiElementSizeClass]"
        placement="bottom-start"
      >
        <template #title="{ iconSize }">
          <slot
            name="title"
            v-bind="{ label, selectedOption, iconSize, selectTitle }"
          >
            <template v-if="multiple">
              <span class="font-normal line-clamp-1">{{ $t(label) }}</span>
              <template v-if="displayCount && selectedOption?.length">
                ({{ selectedOption.length }})
              </template>
            </template>
            <template v-else>
              <label
                v-if="selectTitle"
                class="group-disabled:cursor-not-allowed cursor-pointer"
                :class="buttonType === 'outline' && 'justify-center grow'"
              >
                <div>
                  <div
                    class="flex flex-row gap-2 items-center"
                    :class="buttonType === 'outline' && 'justify-center grow'"
                  >
                    <span
                      class="inline-flex items-center text-left line-clamp-1"
                      >{{ $t(selectTitle) }}</span
                    >
                  </div>
                </div>
              </label>
              <label
                v-else
                class="inline-flex items-center group-disabled:cursor-not-allowed cursor-pointer"
              >
                {{ $t(selectorKeyWord) }}
              </label>
            </template>
          </slot>
        </template>
        <template v-if="displaySelectedOptionsList" #content="{ hide }">
          <slot name="content" v-bind="{ selectedOption }">
            <draggable
              v-if="displaySelectedOptionsList"
              v-bind="{
                animation: 300,
                disabled: false,
                ghostClass: 'ghost',
              }"
              v-model="selectedOption"
              tag="div"
              :disabled="selectedOption?.length < 2 || disabled"
              draggable=".draggable-item"
              item-key="id"
              class="inline-flex gap-0.5 min-h-ui-component otto-base-ui-component h-auto flex-wrap md:pl-0 items-center pt-0 md:pt-2 py-2 -my-px"
            >
              <template #item="{ element }">
                <Tag
                  v-if="getOptionTitle(element)"
                  :size="uiElementTagSize"
                  weight="normal"
                  type="field"
                  tabindex="-1"
                  class="draggable-item"
                  :disabled="isDisabled(element)"
                  :tooltip="$t(tagTooltip)"
                >
                  <span class="inline-flex break-all line-clamp-1">{{
                    getOptionTitle(element)
                  }}</span>
                  <div
                    class="cursor-pointer"
                    @click.stop="toggleOption(element, true, hide)"
                  >
                    <Icon icon="close" size="xs" />
                  </div>
                </Tag>
              </template>
            </draggable>
          </slot>
        </template>
        <template #default="{ hide }">
          <div :class="[display === 'table' && 'px-4 flex']">
            <component
              :is="display === 'table' ? 'vue:tbody' : 'vue:ul'"
              :class="[
                display === 'table'
                  ? 'divide-y divide-solid divide-skin-ui table-auto border-collapse w-full'
                  : '',
              ]"
            >
              <component
                :is="display === 'table' ? 'vue:tr' : 'vue:li'"
                v-if="withSearch"
                class="inline-flex w-full px-2"
              >
                <component
                  :is="display === 'table' ? 'vue:td' : 'vue:div'"
                  colspan="100%"
                  class="opacity-100 px-3 py-2 border-solid border-b border-b-skin-ui w-full"
                >
                  <input
                    type="search"
                    :value="query"
                    :aria-placeholder="$t('search.placeholder')"
                    :placeholder="$t('search.placeholder')"
                    class="text-xs w-full placeholder:text-skin-placeholder bg-transparent"
                    autocomplete="off"
                    @input="query = $event.target.value"
                  />
                </component>
              </component>
              <TransitionGroup
                enter-active-class="transition-opacity ease-in-out"
                enter-from-class="opacity-0"
                enter-to-class="opacity-100"
                leave-active-class="transition-opacity ease-in-out"
                leave-from-class="opacity-100"
                leave-to-class="opacity-0"
                :key="optionList.length"
              >
                <template
                  v-for="option in optionList"
                  :key="option.id"
                >
                  <FormSelectOption
                    :multiple="multiple && ! (option.disableMulti ?? false)"
                    :option="option"
                    :display="display"
                    :selected="option.selected"
                    :disabled="
                      option.disabled || disabledOptions.includes(option.id)
                    "
                    @click="toggleOption(option, option.selected, hide)"
                  >
                    <template v-if="$slots['option']" #default="opts">
                      <slot name="option" v-bind="opts" />
                    </template>
                  </FormSelectOption>
                  <template
                    v-for="subOption in option.options"
                    :key="`subOption_${subOption.id}`"
                  >
                    <FormSelectOption
                      v-if="option.asGroup"
                      :multiple="multiple"
                      :option="subOption"
                      :disabled="subOption.disabled"
                      :display="display"
                      :selected="subOption.selected"
                      class="pl-8"
                      @click="toggleOption(subOption, subOption.selected, hide)"
                    >
                      <template v-if="$slots['option']" #default="opts">
                        <slot name="option" v-bind="opts" />
                      </template>
                    </FormSelectOption>
                  </template>
                </template>
                <FormSelectOption
                  v-if="action"
                  :disabled="action.disabled ?? false"
                  @click="handleActionClick(hide)"
                >
                  <span class="flex gap-2 items-center">
                    <Icon
                      :icon="action.icon"
                      size="sm"
                      class="border border-solid border-transparent"
                    />
                    <span class="truncate">{{ $t(action.label) }}</span>
                  </span>
                </FormSelectOption>
              </TransitionGroup>
            </component>
          </div>
        </template>
      </Dropdown>
    </div>
  </div>
</template>

<script setup>
import { cloneDeep, isEqual } from 'lodash';
import { computed, ref, watch } from 'vue';
import { useI18n } from 'vue-i18n';
import { useUiElement } from '@/composables/useUiElement.js';
import Dropdown from '@/components/dropdown/Dropdown.vue';
import FormSelectOption from './FormSelectOption.vue';
import Icon from '@/components/icon/Icon.vue';
import Tag from '@/components/tag/Tag.vue';
import draggable from 'vuedraggable';

const { t } = useI18n();

import { useFocus } from '@vueuse/core';

const addOption = ref();
const formSelect = ref();
const { focused } = useFocus(addOption);

watch(focused, (focused) => {
  if (focused) {
    console.log('input element has been focused');
  } else {
    console.log('input element has lost focus');
  }
});

function filterValues(values) {
  return values.reduce((acc, option) => {
      const match =
        query.value === ''
          ? true
          : option?.title
              ?.toLowerCase()
              ?.replace(/\s+/g, '')
              ?.replace('.', ',') // to search for decimal numbers
              ?.includes(query.value?.toLowerCase()?.replace('.', ',')?.replace(/\s+/g, ''));

      option.selected = isSelected(option.id);
      if (match) {
        acc.push(option);
      }
      return acc;
    }, []);
}

const emit = defineEmits(['action-click', 'click', 'update:modelValue']);

const props = defineProps({
  id: {
    type: [String, Number],
    default: () => `id_${Math.floor(Math.random() * 10000)}`,
  },
  options: {
    type: Array,
    default: () => [],
  },
  mostUsedOptions: {
    type: Array,
    default: () => [],
  },
  label: {
    type: String,
    default: '',
  },
  size: {
    type: String,
    default: 'md',
  },
  width: {
    type: String,
  },
  selectorKeyWord: {
    type: String,
    required: false,
    default: 'generic.select',
  },
  modelValue: {
    type: [Array, Object, String],
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  filter: {
    type: Boolean,
    default: false,
  },
  withSearch: {
    type: Boolean,
    default: false,
  },
  display: {
    type: String,
    default: 'classic',
  },
  action: {
    type: Object,
    default: null,
  },
  buttonType: {
    type: String,
    default: 'normal',
  },
  multipleDisplay: {
    type: String,
    default: 'tagsList',
    validator(value) {
      return ['tagsList', 'count', 'list'].includes(value);
    },
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  disabledOptions: {
    type: Array,
    default: () => [],
  },
  unselectable: {
    type: Boolean,
    default: false,
  },
  unselectableId: {
    type: Number,
    required: false,
    default: -1,
  },
  unselectableLabel: {
    type: String,
    required: false,
    default: 'dropdown.none',
  },
  tagTooltip: {
    type: String,
    default: 'dropdown.disabled'
  }

});

let query = ref('');
const formSelectId = computed(() => `form-select-${props.id}`);

const selectOptions = computed(() => {
  const options = props.options ?? [];
  return props.unselectable && selectedOption.value?.id
    ? [{ id: props.unselectableId, title: t(props.unselectableLabel) }].concat(
        options
      )
    : options;
});
const getOptionTitle = (optionId) => {
  if (
    typeof optionId === 'object' &&
    selectOptions.value.find((x) => x.id === optionId.id)
  ) {
    const title =
      selectOptions.value.find((x) => x.id === optionId.id)?.title ?? null;
    return title ? t(title) : '';
  } else if (selectOptions.value.find((x) => x.id === optionId)) {
    const title =
      selectOptions.value.find((x) => x.id === optionId)?.title ?? null;
    return title ? t(title) : '';
  } else {
    return null;
  }
};

const selectedOption = computed({
  get: () => {
    return props.modelValue;
  },
  set: (option) => {
    emit('update:modelValue', option);
  },
});

const isSelected = (optionId) => {
  if (props.multiple) {
    return (
      selectedOption.value.findIndex(
        (o) => o === optionId || o.id === optionId
      ) > -1 || selectedOption.value.includes(optionId)
    );
  } else {
    return (
      selectedOption.value?.id === optionId ||
      selectedOption.value === optionId
    );
  }
};

const isDisabled = (optionId) => {
  return props.options.find((option) => option.id == optionId)?.disabled;
};

const displayCount = computed(() => {
  return (
    props.multiple &&
    props.multipleDisplay === 'count' &&
    Array.isArray(selectedOption.value) &&
    selectedOption.value?.length > 0
  );
});
const displaySelectedOptionsList = computed(() => {
  return (
    props.multiple &&
    props.multipleDisplay === 'tagsList' &&
    selectedOption.value?.length > 0
  );
});

const {
  uiElementSizeClass,
  uiElementFieldClass,
  uiElementThemeClass,
  uiElementTagSize,
} = useUiElement(props);

const isButtonNude = computed(() => {
  return ['normal', 'tab'].includes(props.buttonType);
});

const optionList = computed(() => {
  let options = [];

  const mostUsedTemp = cloneDeep(props.mostUsedOptions);
  const otherTemp = cloneDeep(props.options);

  if (mostUsedTemp.length === 0 && otherTemp.length === 0) {
    return [{ id: -1, title: t('dropdown.noOption'), disabled: true, disableMulti: true }];
  }

  const localMostUsed = filterValues(mostUsedTemp);
  const localOptions = filterValues(otherTemp);

  if (localMostUsed?.length > 0) {
    options.push({ id: -1, title: t('dropdown.mostUsed'), disabled: true, disableMulti: true });
    options = options.concat(localMostUsed);
    options.push({ id: -2, title: '', disabled: true, disableMulti: true });
    options.push({ id: -3, title: t('dropdown.other'), disabled: true, disableMulti: true });
  }

  options = options.concat(localOptions);

  if (props.withSearch && query.value !== '' && options?.length === 0) {
    return [{ id: -4, title: t('dropdown.noResult'), disabled: true, disableMulti: true }];
  }

  return options;
});

const mostUsedOptionsList = computed(() => {
  return cloneDeep(props.mostUsedOptions).reduce((acc, option) => {
    option.selected = isSelected(option.id);
    acc.push(option);
    return acc;
  }, []);
});
const toggleOption = (option, selected, hide) => {
  let options = cloneDeep(selectedOption.value);
  const optionId = typeof option === 'object' ? option.id : option;

  if (option.selected || selected) {
    const i = props.multiple
      ? options.findIndex((o) => o.id === optionId || o === optionId)
      : options.id === optionId || options === optionId;
    if (i > -1) {
      if (props.multiple) {
        options.splice(i, 1);
      } else if (props.unselectable) {
        options = null;
      }
    }
  } else {
    props.multiple
      ? options.push(props.filter ? option : optionId)
      : (options = option);
  }
  if (
    (!props.multiple &&
      props.unselectable &&
      isEqual(selectedOption.value, option)) ||
    optionId === -1
  ) {
    options = null;
  }
  emit('update:modelValue', options);
  if (!props.multiple) {
    query.value = '';
    hide();
  }
};
const handleActionClick = (hide) => {
  emit('action-click');
  hide();
};
const selectTitle = computed(() => {
  if (
    !props.multiple &&
    selectedOption.value
  ) {
    if (selectedOption.value?.title) {
      return selectedOption.value.title;
    } else if (selectedOption.value?.name) {
      return selectedOption.value.name;
    } else {
      const checkedId = selectedOption.value.id;
      let title = false;
      let found = false;
      selectOptions.value.forEach((option) => {
        if (option.id === checkedId) {
          title = option.title;
          found = true;
        }
      });
      if (!found) {
        selectOptions.value.forEach((option) => {
          if (option?.options) {
            option.options.forEach(function (subOption) {
              if (subOption.id === checkedId) {
                title = subOption.title;
                found = true;
              }
            });
          }
        });
      }
      return title;
    }
  } else {
    return false;
  }
});
</script>
